export default [
    {
        path: '/success-advisors',
        name: 'success-advisors',
        component: () => import('@/views/pages/users/success-advisors/SuccessAdvisors.vue'),
    },
    {
        path: '/internal-users',
        name: 'internal-users',
        component: () => import('@/views/pages/users/internal-users/InternalUsers.vue'),
    },
    {
        path: '/producers',
        name: 'producers',
        component: () => import('@/views/pages/users/producers/Producers.vue'),
    },
    {
        path: '/technicians',
        name: 'technicians',
        component: () => import('@/views/pages/users/technicians/Technicians.vue'),
    },
];
