import Vue from 'vue';
// import Vuetify from 'vuetify/lib';

//to improve performance
import Vuetify from 'vuetify/lib/framework'


Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#E21D25',
                secondary: '#FCBC04',
            },
        },
    },
});
