export default [
    {
        path: '/cultivar-productivity',
        name: 'productivity',
        component: () => import('@/views/pages/cultivars/ProductivityPanel.vue'),
    },
    {
        path: '/productivity-ranking',
        name: 'productivity-ranking',
        component: () => import('@/views/pages/cultivars/ProductivityRanking.vue'),
    },
];
