export default [
    {
        path: '/financial-records',
        name: 'financial-records',
        component: () => import('@/views/pages/records/financial-records/FinancialRecords.vue'),
    },

    {
        path: '/financial-records/farms/:farmId/harvests/:harvest',
        name: 'financial-record-details',
        component: () => import('@/views/pages/records/financial-records/FinancialRecordDetails.vue'),
    },
];