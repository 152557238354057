import Vue from "vue";
import VueRouter from "vue-router";
import {getToken, saveItem} from '@/services/local-storage.service';

//Routes import
import UsersRoutes from '@/router/users/routes';
import FertilityRecordsRoutes from '@/router/records/fertility-records/routes';
import FinancialRecordsRoutes from "@/router/records/financial-records/routes";
import ManagementRecordsRoutes from "@/router/records/management-records/routes"
import PlotRecordsRoutes from "@/router/records/plot-records/routes";
import FarmsRoutes from '@/router/farms/routes';
import IndexesRoutes from "@/router/indexes/routes";
import AuthoritiesRoutes from "@/router/authorities/routes";
import CultivarBrandsRoutes from "@/router/cultivar-brands/routes";
import CultivarsRoutes from "@/router/cultivars/routes";
import RegisteredCulturesRoutes from "@/router/registered-cultures/routes";
import ProductivityRoutes from "@/router/productivity-panel/routes";
import ProfileRoutes from "@/router/profile/routes";
import RankingsRoutes from "@/router/rankings/routes";


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import("@/views/layouts/Layout.vue"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/Home.vue"),
            },
            ...UsersRoutes,
            ...FertilityRecordsRoutes,
            ...FinancialRecordsRoutes,
            ...ManagementRecordsRoutes,
            ...FarmsRoutes,
            ...IndexesRoutes,
            ...AuthoritiesRoutes,
            ...CultivarBrandsRoutes,
            ...CultivarsRoutes,
            ...RegisteredCulturesRoutes,
            ...PlotRecordsRoutes,
            ...ProductivityRoutes,
            ...ProfileRoutes,
            ...RankingsRoutes,
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/pages/login/Login.vue"),
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("@/views/pages/login/ResetPassword.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    const token = getToken();
    const allowedRoutes = [
        'login',
        'reset-password'
    ];

    if (!token && allowedRoutes.indexOf(to.name) === -1) {
        const destinyRoute = JSON.stringify({...to});
        saveItem('redirectRoute', destinyRoute);
        return next('/login');
    }
    if (token && to.name === 'login') {
        return next('/');
    }

    return next();
});

export default router;
