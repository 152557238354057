export default [
    {
        path: '/fertility-records',
        name: 'fertility-records',
        component: () => import('@/views/pages/records/fertility-records/FertilityRecords.vue'),
    },

    {
        path: '/fertility-records/farms/:farmId/harvests/:harvest',
        name: 'fertility-record-details',
        component: () => import('@/views/pages/records/fertility-records/FertilityRecordDetails.vue'),
    },

    {
        path: '/fertility-records/fields/:fieldId/harvests/:harvest',
        name: 'fertility-record-fields-details',
        component: () => import('@/views/pages/records/fertility-records/FertilityRecordFieldDetails.vue'),
    },
];
