export default [
    {
        path: '/management-records',
        name: 'management-records',
        component: () => import('@/views/pages/records/management-records/ManagementRecords.vue'),
    },

    {
        path: '/management-records/farms/:farmId/harvests/:harvest/cultures/:cultureId',
        name: 'management-record-details',
        component: () => import('@/views/pages/records/management-records/ManagementRecordDetails.vue'),
    },

    {
        path: '/management-records/fields/:fieldId/harvests/:harvest',
        name: 'management-record-fields-details',
        component: () => import('@/views/pages/records/management-records/ManagementRecordFieldDetails.vue'),
    },
];
