import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import toasted from 'vue-toasted';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueTheMask from 'vue-the-mask'
import VueSpeedometer from "vue-speedometer";
import VueGauge from 'vue-gauge';
import VueApexCharts from 'vue-apexcharts';
import money from 'v-money-no-negative';

require('@/views/stylesheets/main.scss');

Vue.config.productionTip = false

Vue.prototype.$loading = false;

import "./filters/date-timezone-filter";
import "./filters/date-filter";

Vue.use(toasted, {
    theme: "bubble",
    className: 'drakkar-toast',
    position: "top-right",
    duration: 5000
});

Vue.use(Loading, {
    color: '#FFF',
    width: 96,
    height: 96,
    backgroundColor: '#E21D25'
});

Vue.use(VueTheMask);
Vue.use(money);
Vue.use(VueApexCharts);



Vue.component('vue-speedometer', VueSpeedometer);

Vue.component('vue-gauge', VueGauge);

Vue.component('vue-apex-chart', VueApexCharts)

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
