export default [
    {
        path: '/ranking',
        name: 'ranking',
        component: () => import('@/views/pages/rankings/Rankings.vue'),
    },

    {
      path: '/rankings/:record',
      name: 'ranking-indexes',
      component: () => import('@/views/pages/rankings/RankingIndexes.vue'),
  },
];
