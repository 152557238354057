const tokenKey = "token";

function saveItem(item, payload) {
  return localStorage.setItem(item, payload);
}

function getItem(item) {
  return localStorage.getItem(item);
}

function removeItem(item) {
  return localStorage.removeItem(item);
}

function saveToken(payload) {
  return localStorage.setItem(tokenKey, payload.access_token);
}
function getToken() {
  return getItem(tokenKey);
}

function saveUser(payload) {
  return localStorage.setItem("user", JSON.stringify(payload));
}
function getUserData() {
  return JSON.parse(getItem("user"));
}

function clearLocalStorage() {
  return localStorage.clear();
}

export {
  saveItem,
  getItem,
  removeItem,
  saveToken,
  getToken,
  saveUser,
  getUserData,
  clearLocalStorage,
};
