export default [
    {
        path: '/plot-records',
        name: 'plot-records',
        component: () => import('@/views/pages/records/plot-records/PlotRecords.vue'),
    },

    {
        path: '/plot-records/:plotRecordId/',
        name: 'plot-record-details',
        component: () => import('@/views/pages/records/plot-records/PlotRecordDetails.vue'),
    },
];
